import React from "react";
import Index from "../Index";

const Announcement = () => {
    return (
        <div>
            {/* <h1>Announcement Page</h1> */}
            <Index />
        </div>
    );
};

export default Announcement;
