export const NEW_CLEARANCE_REQUEST = "NEW_CLEARANCE_REQUEST";

export const NEW_CLEARANCE_SUCCESS = "NEW_CLEARANCE_SUCCESS";

export const NEW_CLEARANCE_RESET = "NEW_CLEARANCE_RESET";

export const NEW_CLEARANCE_FAIL = "NEW_CLEARANCE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GUIDANCE_CLEARANCE_REQUEST = "GUIDANCE_CLEARANCE_REQUEST";

export const GUIDANCE_CLEARANCE_SUCCESS = "GUIDANCE_CLEARANCE_SUCCESS";

export const GUIDANCE_CLEARANCE_FAIL = "GUIDANCE_CLEARANCE_FAIL";

export const DELETE_CLEARANCE_REQUEST = "DELETE_CLEARANCE_REQUEST";

export const DELETE_CLEARANCE_SUCCESS = "DELETE_CLEARANCE_SUCCESS";

export const DELETE_CLEARANCE_RESET = "DELETE_CLEARANCE_RESET";

export const DELETE_CLEARANCE_FAIL = "DELETE_CLEARANCE_FAIL";

export const UPDATE_CLEARANCE_REQUEST = "UPDATE_CLEARANCE_REQUEST";

export const UPDATE_CLEARANCE_SUCCESS = "UPDATE_CLEARANCE_SUCCESS";

export const UPDATE_CLEARANCE_RESET = "UPDATE_CLEARANCE_RESET";

export const UPDATE_CLEARANCE_FAIL = "UPDATE_CLEARANCE_FAIL";

export const CLEARANCE_DETAILS_REQUEST = "CLEARANCE_DETAILS_REQUEST";

export const CLEARANCE_DETAILS_SUCCESS = "CLEARANCE_DETAILS_SUCCESS";

export const CLEARANCE_DETAILS_FAIL = "CLEARANCE_DETAILS_FAIL";
