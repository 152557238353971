export const ALL_NOTIFICATIONS_REQUEST = "ALL_NOTIFICATIONS_REQUEST";
export const ALL_NOTIFICATIONS_SUCCESS = "ALL_NOTIFICATIONS_SUCCESS";
export const ALL_NOTIFICATIONS_FAIL = "ALL_NOTIFICATIONS_FAIL";
export const NOTIFICATION_DETAILS_REQUEST = "NOTIFICATION_DETAILS_REQUEST";
export const NOTIFICATION_DETAILS_SUCCESS = "NOTIFICATION_DETAILS_SUCCESS";
export const NOTIFICATION_DETAILS_FAIL = "NOTIFICATION_DETAILS_FAIL";
export const ADMIN_NOTIFICATIONS_REQUEST = "ADMIN_NOTIFICATIONS_REQUEST";
export const ADMIN_NOTIFICATIONS_SUCCESS = "ADMIN_NOTIFICATIONS_SUCCESS";
export const ADMIN_NOTIFICATIONS_FAIL = "ADMIN_NOTIFICATIONS_FAIL";
export const NEW_NOTIFICATION_REQUEST = "NEW_NOTIFICATION_REQUEST";
export const NEW_NOTIFICATION_SUCCESS = "NEW_NOTIFICATION_SUCCESS";
export const NEW_NOTIFICATION_RESET = "NEW_NOTIFICATION_RESET";
export const NEW_NOTIFICATION_FAIL = "NEW_NOTIFICATION_FAIL";
export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_RESET = "DELETE_NOTIFICATION_RESET";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_RESET = "UPDATE_NOTIFICATION_RESET";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";
export const MARK_NOTIFICATION_AS_READ_REQUEST = "MARK_NOTIFICATION_AS_READ_REQUEST";
export const MARK_NOTIFICATION_AS_READ_SUCCESS = "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAIL = "MARK_NOTIFICATION_AS_READ_FAIL";
export const MARK_NOTIFICATION_AS_READ_RESET = "MARK_NOTIFICATION_AS_READ_RESET";
export const MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST = "MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST";
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = "MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS";
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAIL = "MARK_ALL_NOTIFICATIONS_AS_READ_FAIL";
export const MARK_ALL_NOTIFICATIONS_AS_READ_RESET = "MARK_ALL_NOTIFICATIONS_AS_READ_RESET";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
