export const NEW_VIOLATION_REQUEST = "NEW_VIOLATION_REQUEST";

export const NEW_VIOLATION_SUCCESS = "NEW_VIOLATION_SUCCESS";

export const NEW_VIOLATION_RESET = "NEW_VIOLATION_RESET";

export const NEW_VIOLATION_FAIL = "NEW_VIOLATION_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GUIDANCE_VIOLATIONS_REQUEST = "GUIDANCE_VIOLATIONS_REQUEST";

export const GUIDANCE_VIOLATIONS_SUCCESS = "GUIDANCE_VIOLATIONS_SUCCESS";

export const GUIDANCE_VIOLATIONS_FAIL = "GUIDANCE_VIOLATIONS_FAIL";

export const DELETE_VIOLATION_REQUEST = "DELETE_VIOLATION_REQUEST";

export const DELETE_VIOLATION_SUCCESS = "DELETE_VIOLATION_SUCCESS";

export const DELETE_VIOLATION_RESET = "DELETE_VIOLATION_RESET";

export const DELETE_VIOLATION_FAIL = "DELETE_VIOLATION_FAIL";

export const UPDATE_VIOLATION_REQUEST = "UPDATE_VIOLATION_REQUEST";

export const UPDATE_VIOLATION_SUCCESS = "UPDATE_VIOLATION_SUCCESS";

export const UPDATE_VIOLATION_RESET = "UPDATE_VIOLATION_RESET";

export const UPDATE_VIOLATION_FAIL = "UPDATE_VIOLATION_FAIL";

export const VIOLATION_DETAILS_REQUEST = "VIOLATION_DETAILS_REQUEST";

export const VIOLATION_DETAILS_SUCCESS = "VIOLATION_DETAILS_SUCCESS";

export const VIOLATION_DETAILS_FAIL = "VIOLATION_DETAILS_FAIL";

export const ALL_VIOLATION_REQUEST = "ALL_VIOLATION_REQUEST";

export const ALL_VIOLATION_SUCCESS = "ALL_VIOLATION_SUCCESS";

export const ALL_VIOLATION_FAIL = "ALL_VIOLATION_FAIL";