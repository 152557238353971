export const MONTHLY_SALES_REQUEST = "MONTHLY_SALES_REQUEST";
export const MONTHLY_SALES_SUCCESS = "MONTHLY_SALES_SUCCESS";
export const MONTHLY_SALES_FAIL = "MONTHLY_SALES_FAIL";


export const PRODUCT_SALES_REQUEST = "PRODUCT_SALES_REQUEST";
export const PRODUCT_SALES_SUCCESS = "PRODUCT_SALES_SUCCESS";
export const PRODUCT_SALES_FAIL = "PRODUCT_SALES_FAIL";


export const MONTHLY_REQUESTS_REQUEST = "MONTHLY_REQUESTS_REQUEST"
export const MONTHLY_REQUESTS_SUCCESS = "MONTHLY_REQUESTS_SUCCESS"
export const MONTHLY_REQUESTS_FAIL = "MONTHLY_REQUESTS_FAIL"


export const DOCUMENT_SALES_REQUEST = "DOCUMENT_SALES_REQUEST"
export const DOCUMENT_SALES_SUCCESS = "DOCUMENT_SALES_SUCCESS"
export const DOCUMENT_SALES_FAIL = "DOCUMENT_SALES_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS";