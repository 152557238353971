export const NEW_BALANCE_REQUEST = "NEW_BALANCE_REQUEST";

export const NEW_BALANCE_SUCCESS = "NEW_BALANCE_SUCCESS";

export const NEW_BALANCE_RESET = "NEW_BALANCE_RESET";

export const NEW_BALANCE_FAIL = "NEW_BALANCE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const CASHIER_BALANCE_REQUEST = "CASHIER_BALANCE_REQUEST";

export const CASHIER_BALANCE_SUCCESS = "CASHIER_BALANCE_SUCCESS";

export const CASHIER_BALANCE_FAIL = "CASHIER_BALANCE_FAIL";

export const DELETE_BALANCE_REQUEST = "DELETE_BALANCE_REQUEST";

export const DELETE_BALANCE_SUCCESS = "DELETE_BALANCE_SUCCESS";

export const DELETE_BALANCE_RESET = "DELETE_BALANCE_RESET";

export const DELETE_BALANCE_FAIL = "DELETE_BALANCE_FAIL";

export const UPDATE_BALANCE_REQUEST = "UPDATE_BALANCE_REQUEST";

export const UPDATE_BALANCE_SUCCESS = "UPDATE_BALANCE_SUCCESS";

export const UPDATE_BALANCE_RESET = "UPDATE_BALANCE_RESET";

export const UPDATE_BALANCE_FAIL = "UPDATE_BALANCE_FAIL";

export const BALANCE_DETAILS_REQUEST = "BALANCE_DETAILS_REQUEST";

export const BALANCE_DETAILS_SUCCESS = "BALANCE_DETAILS_SUCCESS";

export const BALANCE_DETAILS_FAIL = "BALANCE_DETAILS_FAIL";

export const ALL_BALANCE_REQUEST = "ALL_BALANCE_REQUEST";

export const ALL_BALANCE_SUCCESS = "ALL_BALANCE_SUCCESS";

export const ALL_BALANCE_FAIL = "ALL_BALANCE_FAIL";





