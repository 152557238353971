export const ALL_DOCUMENTS_REQUEST = "ALL_DOCUMENTS_REQUEST";

export const ALL_DOCUMENTS_SUCCESS = "ALL_DOCUMENTS_SUCCESS";

export const ALL_DOCUMENTS_FAIL = "ALL_DOCUMENTS_FAIL";

export const DOCUMENT_DETAILS_REQUEST = "DOCUMENT_DETAILS_REQUEST";

export const DOCUMENT_DETAILS_SUCCESS = "DOCUMENT_DETAILS_SUCCESS";

export const DOCUMENT_DETAILS_FAIL = "DOCUMENT_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const ADMIN_DOCUMENTS_REQUEST = "ADMIN_DOCUMENTS_REQUEST";

export const ADMIN_DOCUMENTS_SUCCESS = "ADMIN_DOCUMENTS_SUCCESS";

export const ADMIN_DOCUMENTS_FAIL = "ADMIN_DOCUMENTS_FAIL";

export const NEW_DOCUMENT_REQUEST = "NEW_DOCUMENT_REQUEST";

export const NEW_DOCUMENT_SUCCESS = "NEW_DOCUMENT_SUCCESS";

export const NEW_DOCUMENT_RESET = "NEW_DOCUMENT_RESET";

export const NEW_DOCUMENT_FAIL = "NEW_DOCUMENT_FAIL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";

export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";

export const DELETE_DOCUMENT_RESET = "DELETE_DOCUMENT_RESET";

export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";

export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";

export const UPDATE_DOCUMENT_RESET = "UPDATE_DOCUMENT_RESET";

export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL";






