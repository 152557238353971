import React from 'react';
import './BouncingText.css'; // Import your CSS file for styling

const BouncingText = () => {
    return (
        <div className="bouncing-text-container">
            <h1 className="bouncing-text">Available Merch !</h1>
        </div>
    );
};

export default BouncingText;